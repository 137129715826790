import { useEffect, useState } from "react";
import "./singleProjectCSS.scss";
// import { categories } from "../../assets/projectsData";
import ReactPlayer from "react-player";
import { useParams } from "react-router";
import Skeleton from "@mui/material/Skeleton";

import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";

export function SingleProject() {
  const { projectID } = useParams();
  const [loading, setLoading] = useState(true);
  const [projData, setProjData] = useState({
    title: "",
    category: "",
    subCategory: "",
    location: "",
    clientName: "",
    type: "",
    builtArea: "",
    description: "",
    imgUrls: [],
    coverURL: { type: "", url: "" },
  });

  const {
    title,
    // category,
    // subCategory,
    location,
    clientName,
    type,
    builtArea,
    description,
    imgUrls,
    coverURL,
  } = projData;

  async function getProject(props) {
    const q = query(
      collection(props, "projects"),
      where("projectID", "==", projectID)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());

      // if multiple projects with same title, which is rare (edge case)
      // setProjData((prevState) => [
      //   ...prevState,
      //   { id: doc.id, data: doc.data() },
      // ]);
      setProjData(doc.data());
    });
  }

  useEffect(() => {
    getProject(db).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function Cover() {
    return coverURL.type === "image" ? (
      <figure className="imageContainer__thumb">
        <img
          src={coverURL.url}
          alt={title + " cover image"}
          className="imageContainer__image"
        />
      </figure>
    ) : (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/646611564"
          width="100%"
          height="100%"
        />
      </div>
    );
  }

  projData && console.log(projData);
  return (
    <section>
      <div className="row justify-content-end">
        <div className="col-md-10 mt-3">
          <div id="colorlib-page">
            <div id="colorlib-main">
              <section className="ftco-section ftco-bread ">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">Home</a>
                  </span>{" "}
                  <span className="mr-2">
                    <a href="/projects">Projects</a>
                  </span>
                  {/* <span className="mr-2">
                    <a href="/catID">Projects List</a>
                  </span> */}
                  <span>{title}</span>
                </p>
                <h2
                  style={{ letterSpacing: "2px" }}
                  className="bread mt-lg-5 mt-md-4 mt-3 "
                >
                  {loading ? (
                    <Skeleton
                      style={{
                        height: "90px",
                        width: "280px",
                        marginTop: "-10px",
                      }}
                    />
                  ) : (
                    title
                  )}
                </h2>
              </section>

              <section>
                <div className="project-description col-md-10">
                  <h1 className="sub-title d-flex">
                    {loading ? (
                      <Skeleton
                        style={{
                          height: "60px",
                          marginTop: "-10px",
                          width: "200px",
                        }}
                      />
                    ) : (
                      description
                    )}
                  </h1>
                  <h4 className="detail d-flex">
                    Location:&nbsp;&nbsp;
                    {loading ? (
                      <Skeleton
                        style={{
                          height: "50px",
                          marginTop: "-10px",
                          width: "180px",
                        }}
                      />
                    ) : (
                      location
                    )}
                  </h4>
                  <h4 className="detail d-flex">
                    Client:&nbsp;&nbsp;
                    {loading ? (
                      <Skeleton
                        style={{
                          height: "50px",
                          marginTop: "-10px",
                          width: "180px",
                        }}
                      />
                    ) : (
                      clientName
                    )}
                  </h4>
                  <h4 className="detail d-flex">
                    Type:&nbsp;&nbsp;
                    {loading ? (
                      <Skeleton
                        style={{
                          height: "50px",
                          marginTop: "-10px",
                          width: "180px",
                        }}
                      />
                    ) : (
                      type
                    )}
                  </h4>
                  <h4 className="detail d-flex">
                    Built Area:&nbsp;&nbsp;
                    {loading ? (
                      <Skeleton
                        style={{
                          height: "50px",
                          marginTop: "-10px",
                          width: "180px",
                        }}
                      />
                    ) : (
                      builtArea
                    )}
                  </h4>
                </div>
                <div className="imageContainer__mainImage mb-5">
                  <div className="imageContainer__link">
                    {loading ? (
                      <Skeleton
                        sx={{ width: "100%", height: "830px", mt: -20, pt: 0 }}
                      />
                    ) : (
                      <Cover />
                    )}
                  </div>
                </div>
                <section className="ftco-section d-flex row justify-content-center">
                  {imgUrls.map((image, index) => (
                    <div className="gallery" key={index}>
                      <img
                        key={index}
                        src={image}
                        alt={title + " gallery image"}
                        width="600"
                        height="400"
                      />
                    </div>
                  ))}
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
