export const projectsReducer = (state, action) => {
  switch (action.type) {
    //remove totalTables cases later
    case "set-selected-category":
      // const tern = 1;
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    case "set-projects-data":
      // const tern = 1;
      return {
        ...state,
        sortedList: action.sortedList,
      };
    // case "open-device-nav":
    //   return {
    //     ...state,
    //     openDeviceNav: action.open,
    //   };
    // case "set-device":
    //   return {
    //     ...state,
    //     device: action.device,
    //   };
    default:
      throw new Error();
  }
};
