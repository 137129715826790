import { Fragment } from "react";
import { useProjects, useProjectsContext } from "../../hooks";
import { ProjectSubCategories } from "./project-sub-categories";

export function ProjectCategoriesComponent() {
  const { state } = useProjectsContext();
  const { sortedList, selectedCategory } = state;
  const { setSelectedCategory } = useProjects();
  return (
    <Fragment>
      <h6 className="mt-lg-3 mt-md-4 mt-4">
        Click on the categories below to view their subcategories & related
        projects.
      </h6>
      <div className="hero-section">
        <div className="card__test__two-grid">
          {sortedList &&
            sortedList.map((category, index) => (
              <span
                key={index}
                className="card__test__two card__test__three"
                href="#"
                onClick={() => setSelectedCategory(category)}
              >
                <div
                  className={
                    category.catID === selectedCategory.catID
                      ? "card__test__two__background card__test__three__background"
                      : "card__test__two__background"
                  }
                  style={{
                    backgroundImage: `url(${category.categoryImage})`,
                  }}
                ></div>
                <div className="card__test__two__content">
                  <h3 className="card__test__two__heading">{category.title}</h3>
                </div>
              </span>
            ))}
        </div>
      </div>

      {selectedCategory && (
        <div className="container-fluid px-3 px-md-0">
          <div className="row no-gutters slider-text justify-content-end align-items-center">
            <div className="col-md-12">
              {/* <h5>Category Name: {selectedCategory.title}</h5> */}
              <ProjectSubCategories />
            </div>
          </div>{" "}
        </div>
      )}

      {/* END COLORLIB-MAIN */}
    </Fragment>
  );
}
