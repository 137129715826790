import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { db } from "./config/firebase";
import { getProjectsData } from "./functions";

import { Sidebar, Loader } from "./layout";
import { Landing, Services, Blog, Contact } from "./pages";
import { TestTask } from "./pages/testTask";
import { RoutesComponent } from "./Routes.component";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./assets/scss/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [newData, setNewData] = useState();

  useEffect(() => {
    getProjectsData(db).then((d) => setNewData(d));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {isOpen ? (
          <span
            className="js-colorlib-nav-toggle colorlib-nav-toggle active"
            onClick={() => {
              setIsOpen(!isOpen);
              document.body.classList.remove("offcanvas");
            }}
          >
            <i></i>
          </span>
        ) : (
          <span
            className="js-colorlib-nav-toggle colorlib-nav-toggle"
            onClick={() => {
              setIsOpen(!isOpen);
              document.body.classList.add("offcanvas");
            }}
          >
            <i></i>
          </span>
        )}
        <Sidebar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/testTask" element={<TestTask />} />
        </Routes>
        {newData ? (
          <RoutesComponent data={newData} />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "150px",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}

        <Loader />
      </BrowserRouter>
    </div>
  );
}

export default App;
