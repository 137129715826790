import React from "react";
import checklistIcon from "../assets/images/checklistIcon.png";
import diagramIcon from "../assets/images/diagramIcon.png";
import businessmanIcon from "../assets/images/businessmanIcon.png";

export function ServicesComponent() {
  const services = [
    {
      name: "Strategic Design",
      image: checklistIcon,
      text: "MOSS Design Studio establishes a complete understanding of all factors affecting the Client’s portfolio, identifying their impact, analyzing space strategies and modeling various possible scenarios.",
    },
    {
      name: "Project Management",
      image: businessmanIcon,
      text: "MOSS Design Studio professionals coordinate each aspect of the works, from Pre-Construction services, through the Construction phase to Project Closeout, providing specialized skills to lead the construction to its success.",
    },
    {
      name: "Smart Execution",
      image: diagramIcon,
      text: "MOSS Design Studio professionals coordinate each aspect of the works, from Pre-Construction services, through the Construction phase to Project Closeout, providing specialized skills to lead the construction to its success.",
    },
  ];
  return (
    <section>
      <div className="container-fluid px-3 px-md-0">
        <div className="row justify-content-end">
          <div className="col-md-10">
            <div className="row mb-lg-5">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="col-md-4 d-flex align-self-stretch "
                >
                  <div className="services d-block">
                    <div className="icon">
                      <img
                        className="icon-css"
                        src={service.image}
                        alt="service icon"
                      />
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h1 className="heading">{service.name}</h1>
                      <p className="serviceText">{service.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row mt-5">
              <div className="col-md-8 ">
                <p className="services-page-description mb-4">
                  Far far away, behind the Margallah hills in Islamabad, is a
                  group of architects that with dedication makes sure the
                  development of your project fulfills all the requirements and
                  provides you with a productive and attractive space for a
                  positive return and investment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// import React from 'react'

// export  function ServicesComponent() {
//     return (
//         <section >
//             <div className='container-fluid px-3 px-md-0'>
//                 <div className='row justify-content-end'>
//                     <div className='col-md-10'>
//                         <div className='row mb-lg-5'>
//                            <div className='col-md-10 d-flex align-self-stretch '>
//                                 <div className='services d-block'>
//                                     <div className='media-body p-2 '>
//                                         <h1 className="heading">Strategic Design,&nbsp;&nbsp;Effective Project Management&nbsp;&nbsp;and&nbsp;&nbsp;Smart Execution</h1>

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='row mt-5'>
//                             <div className='col-md-8 '>
//                                 <p className="services-page-description">
//                                     Far far away, behind the Margallah hills in Islamabad, is a group of architects that with dedication
//                                     makes sure the development of your project fulfills all the requirements and provides you with a
//                                     productive and attractive space for a positive return and investment.
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }
