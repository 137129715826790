import React from "react";

export function Blog() {
  return (
    <div>
      <div>
        <div id="colorlib-page">
          <div id="colorlib-main">
            <section className="ftco-section ftco-bread ftco-extend-mb">
              <div className="container-fluid px-3 px-md-0">
                <div className="row no-gutters slider-text justify-content-end align-items-center">
                  <div className="col-md-10 ">
                    <p className="breadcrumbs">
                      <span className="mr-2">
                        <a href="index.html">Home</a>
                      </span>{" "}
                      <span>Blog</span>
                    </p>
                    <h1 className="bread">Our blog</h1>
                  </div>
                </div>
              </div>
            </section>
            <section className="ftco-section ftco-no-pt">
              <div className="container-fluid px-3 px-md-0">
                <div className="row justify-content-end">
                  <div className="col-md-10">
                    <div className="row d-flex">
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_1.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_2.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_3.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_4.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_5.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_6.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_7.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="blog-entry ">
                              <a
                                href="/single"
                                className="img img-2"
                                style={{
                                  backgroundImage: `url(${require("../assets/images/image_8.jpg")})`,
                                  height: "45rem",
                                  width: "100%",
                                }}
                              />
                              <div className="text pt-3">
                                <h3 className="mb-2">
                                  <a href="/single">Space Interior Office</a>
                                </h3>
                                <div className="meta-wrap">
                                  <p className="meta">
                                    <span>June 18, 2019</span>
                                    <span>
                                      <a href="/single">Photography</a>
                                    </span>
                                    <span>5 Comment</span>
                                  </p>
                                </div>
                                <p>
                                  <a href="#" className="btn btn-primary">
                                    Read More{" "}
                                    <span className="ion-ios-arrow-forward" />
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* END*/}
                        <div className="row">
                          <div className="col">
                            <div className="block-27">
                              <ul>
                                <li>
                                  <a href="#">&lt;</a>
                                </li>
                                <li className="active">
                                  <span>1</span>
                                </li>
                                <li>
                                  <a href="#">2</a>
                                </li>
                                <li>
                                  <a href="#">3</a>
                                </li>
                                <li>
                                  <a href="#">4</a>
                                </li>
                                <li>
                                  <a href="#">5</a>
                                </li>
                                <li>
                                  <a href="#">&gt;</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 sidebar ">
                        <div className="sidebar-box pt-0">
                          <form action="#" className="search-form">
                            <div className="form-group">
                              <span className="icon icon-search" />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type a keyword and hit enter"
                              />
                            </div>
                          </form>
                        </div>
                        <div className="sidebar-box ">
                          <h3 className="sidebar-heading">Categories</h3>
                          <ul className="categories">
                            <li>
                              <a href="#">
                                Fashion <span>(6)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                Technology <span>(8)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                Travel <span>(2)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                Food <span>(2)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                Photography <span>(7)</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="sidebar-box ">
                          <h3 className="sidebar-heading">Popular Articles</h3>
                          <div className="block-21 mb-4 d-flex">
                            <a
                              className="blog-img mr-4"
                              style={{
                                backgroundImage: `url(${require("../assets/images/image_1.jpg")})`,
                                height: "10rem",
                                width: "100%",
                              }}
                            />
                            <div className="text">
                              <h3 className="heading">
                                <a href="#">
                                  Even the all-powerful Pointing has no control
                                </a>
                              </h3>
                              <div className="meta">
                                <div>
                                  <a href="#">
                                    <span className="icon-calendar" /> June. 18,
                                    2019
                                  </a>
                                </div>
                                <div>
                                  <a href="#">
                                    <span className="icon-person" /> Dave Lewis
                                  </a>
                                </div>
                                <div>
                                  <a href="#">
                                    <span className="icon-chat" /> 19
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="block-21 mb-4 d-flex">
                            <a
                              className="blog-img mr-4"
                              style={{
                                backgroundImage: `url(${require("../assets/images/image_2.jpg")})`,
                                height: "10rem",
                                width: "100%",
                              }}
                            />
                            <div className="text">
                              <h3 className="heading">
                                <a href="#">
                                  Even the all-powerful Pointing has no control
                                </a>
                              </h3>
                              <div className="meta">
                                <div>
                                  <a href="#">
                                    <span className="icon-calendar" /> June. 18,
                                    2019
                                  </a>
                                </div>
                                <div>
                                  <a href="#">
                                    <span className="icon-person" /> Dave Lewis
                                  </a>
                                </div>
                                <div>
                                  <a href="#">
                                    <span className="icon-chat" /> 19
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="block-21 mb-4 d-flex">
                            <a
                              className="blog-img mr-4"
                              style={{
                                backgroundImage: `url(${require("../assets/images/image_3.jpg")})`,
                                height: "10rem",
                                width: "100%",
                              }}
                            />
                            <div className="text">
                              <h3 className="heading">
                                <a href="#">
                                  Even the all-powerful Pointing has no control
                                </a>
                              </h3>
                              <div className="meta">
                                <div>
                                  <a href="#">
                                    <span className="icon-calendar" /> June. 18,
                                    2019
                                  </a>
                                </div>
                                <div>
                                  <a href="#">
                                    <span className="icon-person" /> Dave Lewis
                                  </a>
                                </div>
                                <div>
                                  <a href="#">
                                    <span className="icon-chat" /> 19
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sidebar-box ">
                          <h3 className="sidebar-heading">Tag Cloud</h3>
                          <ul className="tagcloud">
                            <a href="#" className="tag-cloud-link">
                              animals
                            </a>
                            <a href="#" className="tag-cloud-link">
                              human
                            </a>
                            <a href="#" className="tag-cloud-link">
                              people
                            </a>
                            <a href="#" className="tag-cloud-link">
                              cat
                            </a>
                            <a href="#" className="tag-cloud-link">
                              dog
                            </a>
                            <a href="#" className="tag-cloud-link">
                              nature
                            </a>
                            <a href="#" className="tag-cloud-link">
                              leaves
                            </a>
                            <a href="#" className="tag-cloud-link">
                              food
                            </a>
                          </ul>
                        </div>
                        <div className="sidebar-box subs-wrap">
                          <div
                            className="img p-4"
                            style={{
                              backgroundImage: `url(${require("../assets/images/bg_1.jpg")})`,
                              height: "40rem",
                              width: "100%",
                            }}
                          >
                            <div className="overlay" />
                            <h3 className="mb-4 sidebar-heading">Newsletter</h3>
                            <p className="mb-4">
                              Far far away, behind the word mountains, far from
                              the countries Vokalia
                            </p>
                            <form action="#" className="subscribe-form">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email Address"
                                />
                                <input
                                  type="submit"
                                  defaultValue="Subscribe"
                                  className="mt-2 btn btn-white submit"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="sidebar-box ">
                          <h3 className="sidebar-heading">Archives</h3>
                          <ul className="categories">
                            <li>
                              <a href="#">
                                June 2019 <span>(10)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                May 2019 <span>(6)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                April 2019 <span>(8)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                March 2019 <span>(2)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                February 2019 <span>(7)</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                January 2019 <span>(5)</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="sidebar-box ">
                          <h3 className="sidebar-heading">Paragraph</h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Ducimus itaque, autem necessitatibus voluptate
                            quod mollitia delectus aut.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* END COLORLIB-MAIN */}
        </div>
        {/* END COLORLIB-PAGE */}
        {/* loader */}
      </div>
    </div>
  );
}
