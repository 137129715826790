import { Button } from "@mui/material";
import { useState, useEffect } from "react";

import { db, storage } from "../../config/firebase";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  deleteFile,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";

export function DeleteProjectPage() {
  const navigate = useNavigate();
  const { projectID } = useParams();
  const [loading, setLoading] = useState(true);
  const [projData, setProjData] = useState();
  const [docID, setDocID] = useState();

  async function getProject(props) {
    const q = query(
      collection(props, "projects"),
      where("projectID", "==", projectID)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());

      // if multiple projects with same title, which is rare (edge case)
      // setProjData((prevState) => [
      //   ...prevState,
      //   { id: doc.id, data: doc.data() },
      // ]);
      setProjData(doc.data());
      setDocID(doc.id);
    });
  }

  useEffect(() => {
    getProject(db).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = async () => {
    console.log({ projData });

    const path = decodeURIComponent(
      projData.coverURL.url[0].split("o/")[1].split("?")[0]
    );
    const coverRef = ref(storage, path);
    // Delete the file
    deleteObject(coverRef)
      .then(() => {
        console.log("file delete succesfully");
      })
      .catch((error) => {
        console.log({ error });
      });

    projData.imgUrls.forEach(async (fileUrl) => {
      const path = decodeURIComponent(fileUrl.split("o/")[1].split("?")[0]);
      const imagesRef = ref(storage, path);
      // Delete the file
      deleteObject(imagesRef)
        .then(() => {
          console.log("file delete succesfully");
        })
        .catch((error) => {
          console.log({ error });
        });
    });
    const docDel = await deleteDoc(doc(db, "projects", docID));

    navigate("/delete-project");
  };

  console.log({ projData });
  return (
    <div id="colorlib-page">
      <div id="colorlib-main">
        <section className="ftco-section ftco-bread">
          <div className="container-fluid px-3 px-md-0">
            <div className="row no-gutters slider-text justify-content-end align-items-center">
              <div className="col-md-10">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">Home&nbsp;</a>
                  </span>
                  <span className="mr-2">
                    <a href="/admin">Admin&nbsp;</a>
                  </span>
                  <span>Delete Project</span>
                </p>
                <h1 className="bread mt-lg-5 mt-md-4 mt-3">Delete Project</h1>
                {projData && (
                  <span className="d-flex flex-column text-center mt-5">
                    <h4 className="mt-5">
                      Are you sure you want to delete this project
                    </h4>
                    <div>
                      <Button
                        variant="contained"
                        color="error"
                        className="mt-3"
                        onClick={() => onDelete()}
                      >
                        Confirm
                      </Button>
                    </div>
                    <div className="card__project12 text-center 1">
                      <div className="card__project12_image text-centers">
                        {projData.title}
                        <img src={projData.coverURL.url} alt="Project" />
                      </div>
                    </div>
                  </span>
                )}

                {/* <AddProjectForm /> */}
              </div>
            </div>
          </div>
        </section>
        {/* END COLORLIB-MAIN */}
      </div>
      {/* END COLORLIB-PAGE */}
    </div>
  );
}
