import { useProjectsContext } from "./use-projects-context.hook";

export const useProjects = () => {
  const { state, dispatch } = useProjectsContext();
  return {
    state,
    setProjectsData: (sortedList) =>
      dispatch({ type: "set-projects-data", sortedList }),
    setSelectedCategory: (selectedCategory) =>
      dispatch({ type: "set-selected-category", selectedCategory }),
    setSelectedProject: (selectedProject) =>
      dispatch({ type: "set-selected-project", selectedProject }),
    // setDevice: (device) => dispatch({ type: "set-device", device }),
  };
};
