import { Link } from "react-router-dom";
import { useProjectsContext } from "../../hooks";

export function DeleteProjectList() {
  const { state } = useProjectsContext();
  const { projectList } = state;
  return (
    <div>
      <div id="colorlib-page">
        <div id="colorlib-main">
          <section className="ftco-section ftco-bread">
            <div className="container-fluid px-3 px-md-0">
              <div className="row no-gutters slider-text justify-content-end align-items-center">
                <div className="col-md-10">
                  <p className="breadcrumbs">
                    <span className="mr-2">
                      <a href="/">Home&nbsp;</a>
                    </span>
                    <span className="mr-2">
                      <a href="/admin">Admin&nbsp;</a>
                    </span>
                    <span>Delete Project</span>
                  </p>
                  <h1 className="bread mt-lg-5 mt-md-4 mt-3">Delete Project</h1>

                  <span className="d-flex flex-column text-center">
                    <div className="card__project12-list">
                      {projectList.map((project, index) => (
                        <Link
                          to={`/delete-project/${project.projectID}`}
                          key={index}
                        >
                          <div className="card__project12 1">
                            {" "}
                            <div className="card__project12_image text-center">
                              {project.title}
                              <img src={project.coverURL.url} alt="Project" />
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </span>

                  {/* <AddProjectForm /> */}
                </div>
              </div>
            </div>
          </section>
          {/* END COLORLIB-MAIN */}
        </div>
        {/* END COLORLIB-PAGE */}
      </div>
    </div>
  );
}
