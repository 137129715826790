import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  serverTimestamp,
  // doc,
  // updateDoc,
} from "firebase/firestore";
// import {
//   getStorage,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from "firebase/storage";
import { db } from "../../../config/firebase";
import { storeImage } from "../../../functions";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { validateProjectInput } from "../../../validation/projectForm";
import "../../../assets/scss/style.scss";

// import Compressor from "compressorjs";

export function AddProjectForm() {
  const [subCategoryItems, setSubCategoryItems] = useState();
  const [formErrors, setFormErrors] = useState({
    title: "",
    category: "",
    subCategory: "",
    location: "",
    clientName: "",
    type: "",
    builtArea: "",
    description: "",
  });
  const [imageVideoRadioBtn, setImageVideoRadioBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    category: "",
    subCategory: "",
    location: "",
    clientName: "",
    type: "",
    builtArea: "",
    description: "",
    mainVideoUrl: "",
    mainImage: {},
    images: {},
  });

  const {
    title,
    category,
    subCategory,
    location,
    clientName,
    type,
    builtArea,
    description,
    mainVideoUrl,
    images,
    mainImage,
  } = formData;

  const auth = getAuth();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  // const styles = (theme) => ({
  //   textField: {
  //     width: "90%",
  //     marginLeft: "auto",
  //     marginRight: "auto",
  //     paddingBottom: 0,
  //     marginTop: 0,
  //     fontWeight: 500,
  //   },
  //   input: {
  //     color: "white",
  //   },
  // });

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ ...formData, userRef: user.uid });
        } else {
          navigate("/");
        }
      });
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (images.length > 10) {
      setLoading(false);
      toast.error("Max 10 images");
      return;
    }

    // Store image in firebase
    // const storeImage = async (image) => {
    //   return new Promise((resolve, reject) => {
    //     const storage = getStorage();
    //     const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;

    //     const storageRef = ref(storage, "images/" + fileName);

    //     new Compressor(image, {
    //       quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //       success: (compressedResult) => {
    //         // compressedResult has the compressed file.
    //         // Use the compressed file to upload the images to your server.
    //         const uploadTask = uploadBytesResumable(
    //           storageRef,
    //           compressedResult
    //         );
    //         uploadTask.on(
    //           "state_changed",
    //           (snapshot) => {
    //             const progress =
    //               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //             console.log("Upload is " + progress + "% done");
    //             switch (snapshot.state) {
    //               case "paused":
    //                 console.log("Upload is paused");
    //                 break;
    //               case "running":
    //                 console.log("Upload is running");
    //                 break;
    //               default:
    //                 break;
    //             }
    //           },
    //           (error) => {
    //             reject(error);
    //           },
    //           () => {
    //             // Handle successful uploads on complete
    //             // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //               resolve(downloadURL);
    //             });
    //           }
    //         );
    //       },
    //     });
    //   });
    // };

    const { errors, isValid } = validateProjectInput(formData);

    // Check Validation
    if (!isValid) {
      // If any errors, send 400 with errors object
      setLoading(false);
      console.log(errors);
      return setFormErrors(errors);
    } else {
      const coverUrl =
        imageVideoRadioBtn === "image"
          ? await Promise.all(
              [...mainImage].map((image) => storeImage(image))
            ).catch(() => {
              setLoading(false);
              toast.error("Images not uploaded");
              return;
            })
          : mainVideoUrl;

      const imgUrls = await Promise.all(
        [...images].map((image) => storeImage(image))
      ).catch(() => {
        setLoading(false);
        toast.error("Images not uploaded");
        return;
      });
      const formDataCopy = {
        ...formData,
        coverURL: { type: imageVideoRadioBtn, url: coverUrl },
        imgUrls,
        projectID: `${formData.title.replace(/\s+/g, "")}-${uuidv4()}`,
        timestamp: serverTimestamp(),
      };
      delete formDataCopy.images;
      delete formDataCopy.mainImage;
      delete formDataCopy.mainVideoUrl;

      const docRef = await addDoc(collection(db, "projects"), formDataCopy);
      // const docRef = await db
      //   .collection("projects")
      //   .doc(`${formData.title.replace(/\s+/g, "")}-${uuidv4()}`)
      //   .set(formDataCopy);
      console.log({ docRef });
    }
    // const docRef = await addDoc(collection(db, "projects"), formDataCopy);
    // console.log({ docRef });
    setLoading(false);
    toast.success("Listing saved");
  };

  //On Mutate
  const onMutate = (e) => {
    let boolean = null;

    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }

    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        images: e.target.files,
      }));
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  };

  //On Main Image/Video Mutate
  const onMainImageVideoMutate = (e) => {
    let boolean = null;

    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }

    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        mainImage: e.target.files,
      }));
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  };

  //logic for select dropdowns data to add to formData
  const categorySelectHandler = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      category: e.target.value,
    }));
    switch (e.target.value) {
      case "ArchitectureDesign":
        return setSubCategoryItems(["Commercial", "Residential", "Corporate"]);
      case "DesignConsultancy":
        return setSubCategoryItems(["Corporate", "Residential"]);
      case "MasterPlanning":
        return setSubCategoryItems(["Town Planning", "Complex Planning"]);
      case "MarketingContent":
        return setSubCategoryItems([
          "Exterior & Interior",
          "Virtual Reality",
          "Print Media",
          "Misc",
        ]);
      default:
        return "";
    }
  };
  const subCategorySelectHandler = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      subCategory: e.target.value,
    }));
  };
  const imageVideoRadioBtnHandler = (event) => {
    setImageVideoRadioBtn(event.target.value);
    setFormData((prevState) => ({
      ...prevState,
      mainVideoUrl: "",
      mainImage: {},
    }));
  };
  console.log({ formData });
  return (
    <div className="row no-gutters slider-text ml-5 mt-5">
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "150px",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div className="col-md-6">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            columnSpacing={6}
          >
            <TextField
              id="title"
              value={title}
              onChange={onMutate}
              maxLength="32"
              minLength="10"
              required
              label="Project name"
              variant="filled"
              sx={{ mt: 2, minWidth: 350 }}
              error={formErrors.title ? true : false}
              helperText={formErrors.title && `${formErrors.title}`}
              InputProps={{
                className: "muiTextField",
              }}
            />

            {/* <h5>Project Category:</h5> */}
            <FormControl variant="filled">
              <InputLabel id="category">Category</InputLabel>
              <Select
                labelId="category"
                id="category"
                value={category}
                onChange={categorySelectHandler}
                required
                sx={{ mt: 2, minWidth: 350 }}
                error={formErrors.category ? true : false}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="ArchitectureDesign">
                  Architecture Design
                </MenuItem>
                <MenuItem value="DesignConsultancy">
                  Design Consultancy
                </MenuItem>
                <MenuItem value="MasterPlanning">Master Planning</MenuItem>
                <MenuItem value="MarketingContent">Marketing Content</MenuItem>
              </Select>
              <p
                style={{
                  color: "#D32F2F",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                {formErrors.category && `${formErrors.category}`}
              </p>
            </FormControl>
            {/* <h5>Project Sub-Category:</h5> */}
            <FormControl variant="filled">
              <InputLabel id="subCategory">Sub Category</InputLabel>
              <Select
                labelId="sub-category-label"
                id="subCategory"
                value={subCategory}
                onChange={subCategorySelectHandler}
                required
                sx={{ mt: 2, minWidth: 350 }}
                error={formErrors.subCategory ? true : false}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {subCategoryItems &&
                  subCategoryItems.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
              <p
                style={{
                  color: "#D32F2F",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                {formErrors.subCategory && `${formErrors.subCategory}`}
              </p>
            </FormControl>
            <TextField
              id="description"
              value={description}
              onChange={onMutate}
              label="Description"
              required
              multiline
              rows={4}
              variant="filled"
              sx={{ mt: 2, minWidth: 350 }}
              error={formErrors.description ? true : false}
              helperText={formErrors.description && `${formErrors.description}`}
              InputProps={{
                className: "muiTextArea",
              }}
            />
            <TextField
              id="location"
              value={location}
              onChange={onMutate}
              required
              label="Location"
              variant="filled"
              sx={{ mt: 2, minWidth: 350 }}
              error={formErrors.location ? true : false}
              helperText={formErrors.location && `${formErrors.location}`}
              InputProps={{
                className: "muiTextField",
              }}
            />
            <TextField
              id="clientName"
              value={clientName}
              onChange={onMutate}
              required
              label="Client Name"
              variant="filled"
              sx={{ mt: 2, minWidth: 350 }}
              error={formErrors.clientName ? true : false}
              helperText={formErrors.clientName && `${formErrors.clientName}`}
              InputProps={{
                className: "muiTextField",
              }}
            />
            <TextField
              id="type"
              value={type}
              onChange={onMutate}
              label="Type"
              required
              variant="filled"
              sx={{ mt: 2, minWidth: 350 }}
              error={formErrors.type ? true : false}
              helperText={formErrors.type && `${formErrors.type}`}
              InputProps={{
                className: "muiTextField",
              }}
            />
            <TextField
              id="builtArea"
              value={builtArea}
              required
              onChange={onMutate}
              label="Built Area"
              variant="filled"
              sx={{ mt: 2, minWidth: 350 }}
              error={formErrors.builtArea ? true : false}
              helperText={formErrors.builtArea && `${formErrors.builtArea}`}
              InputProps={{
                className: "muiTextField",
              }}
            />
            <h5 className="formLabel" style={{ marginTop: "1em" }}>
              Cover Image/Video
            </h5>

            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                This image/video will be displayed in cover.
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={imageVideoRadioBtnHandler}
              >
                <FormControlLabel
                  value="image"
                  control={<Radio />}
                  label="Image"
                />
                <FormControlLabel
                  value="video"
                  control={<Radio />}
                  label="video"
                />
              </RadioGroup>
            </FormControl>
            {imageVideoRadioBtn === "image" && (
              <input
                className="formInputFile"
                type="file"
                id="images"
                onChange={onMainImageVideoMutate}
                max="1"
                accept=".jpg,.png,.jpeg"
                required
              />
            )}
            {imageVideoRadioBtn === "video" && (
              <TextField
                id="mainVideoUrl"
                value={mainVideoUrl}
                required
                onChange={onMainImageVideoMutate}
                label="Cover Video Vimeo URL"
                variant="filled"
                sx={{ mt: 2, minWidth: 350 }}
              />
            )}
            <h5 className="formLabel" style={{ marginTop: "1.5em" }}>
              Gallery Images
            </h5>
            <p className="imagesInfo">
              These images will be displayed under the cover (max 6).
            </p>
            <input
              className="formInputFile"
              type="file"
              id="images"
              onChange={onMutate}
              max="6"
              accept=".jpg,.png,.jpeg"
              multiple
              required
            />
            <Button
              onClick={onSubmit}
              variant="contained"
              sx={{ mt: 10, mb: 5 }}
            >
              Submit
            </Button>
          </Grid>
        </div>
      )}
    </div>
  );
}

// import { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";

// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import {
//   addDoc,
//   collection,
//   serverTimestamp,
//   doc,
//   updateDoc,
//   getDocs,
// } from "firebase/firestore";
// import {
//   getStorage,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from "firebase/storage";
// import { db } from "../../config/firebase";

// import TextField from "@mui/material/TextField";
// import Grid from "@mui/material/Grid";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { toast } from "react-toastify";
// import { Button } from "@mui/material";

// import Compressor from "compressorjs";

// export function AddProjectForm() {
//   const [subCategoryItems, setSubCategoryItems] = useState();
//   const [data, setData] = useState();
//   const [selectedCategory, setSelectedCategory] = useState();
//   const [selectedSubCategory, setSelectedSubCategory] = useState();

//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState({
//     title: "",
//     location: "",
//     type: "",
//     builtArea: "",
//     description: "",
//     images: {},
//   });

//   const { title, location, type, builtArea, description, images } = formData;

//   const auth = getAuth();
//   const navigate = useNavigate();
//   const isMounted = useRef(true);

//   // Fetch listing to edit
//   useEffect(() => {
//     setLoading(true);
//     const fetchListing = async () => {
//       const docRef = collection(db, "categories");
//       const docSnap = await getDocs(docRef);
//       const cityList = docSnap.docs.map((doc) => doc.data());
//       if (cityList) {
//         setData(cityList);
//         setLoading(false);
//       } else {
//         navigate("/");
//         toast.error("Listing does not exist");
//       }
//     };

//     fetchListing();
//   }, [navigate]);

//   useEffect(() => {
//     if (isMounted) {
//       onAuthStateChanged(auth, (user) => {
//         if (user) {
//           setFormData({ ...formData, userRef: user.uid });
//         } else {
//           navigate("/");
//         }
//       });
//     }

//     return () => {
//       isMounted.current = false;
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isMounted]);

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     setLoading(true);

//     if (images.length > 10) {
//       setLoading(false);
//       toast.error("Max 10 images");
//       return;
//     }

//     // Store image in firebase
//     const storeImage = async (image) => {
//       return new Promise((resolve, reject) => {
//         const storage = getStorage();
//         const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;

//         const storageRef = ref(storage, "images/" + fileName);

//         new Compressor(image, {
//           quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
//           success: (compressedResult) => {
//             // compressedResult has the compressed file.
//             // Use the compressed file to upload the images to your server.
//             const uploadTask = uploadBytesResumable(
//               storageRef,
//               compressedResult
//             );
//             uploadTask.on(
//               "state_changed",
//               (snapshot) => {
//                 const progress =
//                   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 console.log("Upload is " + progress + "% done");
//                 switch (snapshot.state) {
//                   case "paused":
//                     console.log("Upload is paused");
//                     break;
//                   case "running":
//                     console.log("Upload is running");
//                     break;
//                   default:
//                     break;
//                 }
//               },
//               (error) => {
//                 reject(error);
//               },
//               () => {
//                 // Handle successful uploads on complete
//                 // For instance, get the download URL: https://firebasestorage.googleapis.com/...
//                 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//                   resolve(downloadURL);
//                 });
//               }
//             );
//           },
//         });
//       });
//     };

//     const imgUrls = await Promise.all(
//       [...images].map((image) => storeImage(image))
//     ).catch(() => {
//       setLoading(false);
//       toast.error("Images not uploaded");
//       return;
//     });

//     const formDataCopy = {
//       ...formData,
//       imgUrls,
//       timestamp: serverTimestamp(),
//     };

//     delete formDataCopy.images;

//     const docRef = await addDoc(
//       collection(db, `categories/${selectedCategory}`),
//       formDataCopy
//     );
//     // const docRef = doc(db, category);
//     // await updateDoc(docRef, formDataCopy);
//     setLoading(false);
//     toast.success("Listing saved");
//     console.log({ docRef });
//     // navigate(`/projects`);
//   };

//   const onMutate = (e) => {
//     let boolean = null;

//     if (e.target.value === "true") {
//       boolean = true;
//     }
//     if (e.target.value === "false") {
//       boolean = false;
//     }

//     // Files
//     if (e.target.files) {
//       setFormData((prevState) => ({
//         ...prevState,
//         images: e.target.files,
//       }));
//     }

//     // Text/Booleans/Numbers
//     if (!e.target.files) {
//       setFormData((prevState) => ({
//         ...prevState,
//         [e.target.id]: boolean ?? e.target.value,
//       }));
//     }
//   };

//   //logic for select dropdowns data to add to formData
//   const categorySelectHandler = (e) => {
//     setSelectedCategory(e.target.value);
//     switch (e.target.value) {
//       case "Architecture Design":
//         return setSubCategoryItems(["Commercial", "Residential", "Corporate"]);
//       case "Design Consultancy":
//         return setSubCategoryItems(["Corporate", "Residential"]);
//       case "Master Planning":
//         return setSubCategoryItems(["Town planning", "Town planning"]);
//       case "Marketing Content":
//         return setSubCategoryItems([
//           "Exterior and Interior",
//           "VRs",
//           "Print Media",
//           "Misc",
//         ]);
//       default:
//         return "";
//     }
//   };
//   const subCategorySelectHandler = (e) => {
//     setSelectedSubCategory(e.target.value);
//   };

//   return (
//     <div className="row no-gutters slider-text justify-content-center align-items-center">
//       {loading ? (
//         <div>
//           <p>Loading....</p>
//         </div>
//       ) : (
//         <div className="col-md-6">
//           <Grid
//             container
//             direction="column"
//             justifyContent="center"
//             alignItems="flex-start"
//             columnSpacing={6}
//           >
//             <TextField
//               id="title"
//               value={title}
//               onChange={onMutate}
//               maxLength="32"
//               minLength="10"
//               required
//               label="Project name"
//               variant="filled"
//               sx={{ mt: 2, minWidth: 350 }}
//             />
//             {/* <h5>Project Category:</h5> */}
//             <FormControl variant="filled">
//               <InputLabel id="category">Category</InputLabel>
//               <Select
//                 labelId="category"
//                 id="category"
//                 value={selectedCategory}
//                 onChange={categorySelectHandler}
//                 required
//                 sx={{ mt: 2, minWidth: 350 }}
//               >
//                 <MenuItem value="">
//                   <em>None</em>
//                 </MenuItem>
//                 <MenuItem value="ArchitectureDesign">
//                   Architecture Design
//                 </MenuItem>
//                 <MenuItem value="DesignConsultancy">
//                   Design Consultancy
//                 </MenuItem>
//                 <MenuItem value="MasterPlanning">Master Planning</MenuItem>
//                 <MenuItem value="MarketingContent">Marketing Content</MenuItem>
//               </Select>
//             </FormControl>
//             {/* <h5>Project Sub-Category:</h5> */}
//             <FormControl variant="filled">
//               <InputLabel id="subCategory">Sub Category</InputLabel>
//               <Select
//                 labelId="sub-category-label"
//                 id="subCategory"
//                 value={selectedSubCategory}
//                 onChange={subCategorySelectHandler}
//                 required
//                 sx={{ mt: 2, minWidth: 350 }}
//               >
//                 <MenuItem value="">
//                   <em>None</em>
//                 </MenuItem>
//                 {subCategoryItems &&
//                   subCategoryItems.map((item) => {
//                     return <MenuItem value={item}>{item}</MenuItem>;
//                   })}
//               </Select>
//             </FormControl>
//             <TextField
//               id="description"
//               value={description}
//               onChange={onMutate}
//               label="Description"
//               multiline
//               rows={4}
//               variant="filled"
//               sx={{ mt: 2, minWidth: 350 }}
//             />
//             <TextField
//               id="location"
//               value={location}
//               onChange={onMutate}
//               label="Location"
//               variant="filled"
//               sx={{ mt: 2, minWidth: 350 }}
//             />
//             <TextField
//               id="type"
//               value={type}
//               onChange={onMutate}
//               label="Type"
//               variant="filled"
//               sx={{ mt: 2, minWidth: 350 }}
//             />
//             <TextField
//               id="builtArea"
//               value={builtArea}
//               onChange={onMutate}
//               label="Built Area"
//               variant="filled"
//               sx={{ mt: 2, mb: 10, minWidth: 350 }}
//             />
//             <label className="formLabel">Images</label>
//             <p className="imagesInfo">
//               The first image will be the cover (max 6).
//             </p>
//             <input
//               className="formInputFile"
//               type="file"
//               id="images"
//               onChange={onMutate}
//               max="6"
//               accept=".jpg,.png,.jpeg"
//               multiple
//               required
//             />
//             <Button onClick={onSubmit} variant="contained">
//               Submit
//             </Button>
//           </Grid>
//         </div>
//       )}
//     </div>
//   );
// }
