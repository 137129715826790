import React from "react";
import "./team-card.css";
import person1 from "../assets/images/person_1.jpeg";
import person2 from "../assets/images/person_2.jpeg";
import person3 from "../assets/images/person_3.jpg";
import person6 from "../assets/images/person_6.jpeg";

export default function Whoweare() {
  return (
    <ul className="cards__team">
      <li>
        <div className="card__team">
          <img src={person1} className="card__team__image" alt="" />
          <div className="card__team__overlay">
            <div className="card__team__header">
              <h3 className="card__team__title">Obaid ur Rehman</h3>
            </div>
            <div className="px-3">
              <p className="card__team__status">
                Managing Partner / Chief Executive Officer
              </p>
              {/* <p className="card__team__description">
                Confident business expert with an eye for finesse in creativity
              </p> */}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="card__team">
          <img src={person2} className="card__team__image" alt="" />
          <div className="card__team__overlay">
            <div className="card__team__header">
              <h3 className="card__team__title">Sohaib Nawaz</h3>
            </div>
            <div className=" px-3">
              <p className="card__team__status">
                Managing Partner / Senior Architect
              </p>
              {/* <p className="card__team__description">
                A lead designer with a curious brain and an out of the box
                aesthetic
              </p> */}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="card__team">
          <img src={person6} className="card__team__image" alt="" />
          <div className="card__team__overlay">
            <div className="card__team__header">
              <h5 className="card__team__title">Munib Ahmed Shah</h5>
            </div>
            <div className="px-3">
              <p className="card__team__status">
                Managing Partner / Senior Architect
              </p>
              {/* <p className="card__team__description">
                An individual that makes things happen, who also happens to be
                an amazing architect
              </p> */}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="card__team">
          <img src={person3} className="card__team__image" alt="" />
          <div className="card__team__overlay">
            <div className="card__team__header">
              <h5 className="card__team__title">Zaheer Khan</h5>
            </div>
            <div className="px-3">
              <p className="card__team__status">
                Associate Architect / Partner
              </p>
              {/* <p className="card__team__description">
                A confident, smart, and versatile designer with hands on
                material handling expertise
              </p> */}
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}
