import { useState, useEffect } from "react";

import { db } from "../../config/firebase";

import EditProjectForm from "./components/edit-project-form";
import { useParams } from "react-router";
import { collection, query, where, getDocs } from "firebase/firestore";

export function EditProjectPage() {
  const { projectID } = useParams();
  const [loading, setLoading] = useState(true);
  const [projData, setProjData] = useState();

  async function getProject(props) {
    const q = query(
      collection(props, "projects"),
      where("projectID", "==", projectID)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());

      // if multiple projects with same title, which is rare (edge case)
      // setProjData((prevState) => [
      //   ...prevState,
      //   { id: doc.id, data: doc.data() },
      // ]);
      setProjData(doc.data());
    });
  }

  useEffect(() => {
    getProject(db).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="colorlib-page">
      <div id="colorlib-main">
        <section className="ftco-section ftco-bread">
          <div className="container-fluid px-3 px-md-0">
            <div className="row no-gutters slider-text justify-content-end align-items-center">
              <div className="col-md-10">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">Home&nbsp;</a>
                  </span>
                  <span className="mr-2">
                    <a href="/admin">Admin&nbsp;</a>
                  </span>
                  <span>Edit Project</span>
                </p>
                <h1 className="bread mt-lg-5 mt-md-4 mt-3">Edit Project</h1>
                {projData && <EditProjectForm data={projData} />}
              </div>
            </div>
          </div>
        </section>
        {/* END COLORLIB-MAIN */}
      </div>
      {/* END COLORLIB-PAGE */}
    </div>
  );
}
