import { Fragment, useReducer } from "react";
import { Routes, Route } from "react-router-dom";
import { ProjectsContext } from "./contexts";
import { LoginPage } from "./auth/login-page";
import { projectsReducer } from "./functions";
import { auth } from "./config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import {
  SingleProject,
  ProjectCategories,
  Admin,
  AddProjectPage,
  EditProjectList,
  EditProjectPage,
  DeleteProjectList,
  DeleteProjectPage,
} from "./pages";

export function RoutesComponent({ data }) {
  const { sortedList, projectList } = data;
  const [user] = useAuthState(auth);

  const [state, dispatch] = useReducer(projectsReducer, {
    sortedList,
    projectList,
    selectedCategory: "",
    selectedProject: "",
  });
  return (
    <ProjectsContext.Provider value={{ state, dispatch }}>
      <Routes>
        {user ? (
          <>
            <Route path="/add-project" element={<AddProjectPage />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/edit-project" element={<EditProjectList />} />
            <Route
              path="/edit-project/:projectID"
              element={<EditProjectPage />}
            />
            <Route path="/delete-project" element={<DeleteProjectList />} />
            <Route
              path="/delete-project/:projectID"
              element={<DeleteProjectPage />}
            />
          </>
        ) : (
          <>
            <Route path="/admin" element={<LoginPage />} />
            <Route path="/add-project" element={<LoginPage />} />
            <Route path="/edit-project" element={<LoginPage />} />
            <Route path="/edit-project/:projectID" element={<LoginPage />} />
            <Route path="/delete-project/:projectID" element={<LoginPage />} />
          </>
        )}
        <Route path="/projects" element={<ProjectCategories />} />
        <Route path="/projects/:projectID" element={<SingleProject />} />
      </Routes>
    </ProjectsContext.Provider>
  );
}
