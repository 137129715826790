import { collection, getDocs } from "firebase/firestore";

export const getProjectsData = async (props) => {
  var sortedList = [];
  const projectsCol = collection(props, "projects");
  const projectsSnapshot = await getDocs(projectsCol);
  const projectList = projectsSnapshot.docs.map((doc) => doc.data());

  //Architecture Design category array filter
  const ad = projectList.filter(
    (project) => project.category === "ArchitectureDesign" && project
  );
  const adr = ad.reduce((acc, item) => {
    if (!acc[item.subCategory]) {
      acc[item.subCategory] = [];
    }
    acc[item.subCategory].push(item);
    return acc;
  }, {});
  var adra = Object.entries(adr);
  adr &&
    sortedList.push({
      title: "Architecture Design",
      data: adra,
      catID: "cat0",
      categoryImage:
        "https://api.pcloud.com/getpubthumb?code=XZaTGqXZRLndkvL9wRRjf81negYNaVSSPMyy&linkpassword=undefined&size=2048x1152&crop=0&type=auto",
    }); //Architecture Design Array reduced to subcategories

  //Design Consultancy category array filter
  const dc = projectList.filter(
    (project) => project.category === "DesignConsultancy" && project
  );
  const dcr = dc.reduce((acc, item) => {
    if (!acc[item.subCategory]) {
      acc[item.subCategory] = [];
    }
    acc[item.subCategory].push(item);
    return acc;
  }, {});
  var dcra = Object.entries(dcr);
  dcr &&
    sortedList.push({
      title: "Design Consultancy",
      data: dcra,
      catID: "cat1",
      categoryImage:
        "https://api.pcloud.com/getpubthumb?code=0QW7&linkpassword=undefined&size=1920x1080&crop=0&type=auto",
    }); //Design Consultancy Array reduced to subcategories

  //Master Planning category array filter
  const mp = projectList.filter(
    (project) => project.category === "MasterPlanning" && project
  );
  const mpr = mp.reduce((acc, item) => {
    if (!acc[item.subCategory]) {
      acc[item.subCategory] = [];
    }
    acc[item.subCategory].push(item);
    return acc;
  }, {});
  var mpra = Object.entries(mpr);
  mpr &&
    sortedList.push({
      title: "Master Planning",
      data: mpra,
      catID: "cat2",
      categoryImage:
        "https://api.pcloud.com/getpubthumb?code=XZzPIqXZy3hjNHsHCoflEtuqUHWPR5BMMHIV&linkpassword=undefined&size=1920x1080&crop=0&type=auto",
    }); //Master Planning Array reduced to subcategories

  //Marketing Content category array filter
  const mc = projectList.filter(
    (project) => project.category === "MarketingContent" && project
  );
  const mcr = mc.reduce((acc, item) => {
    if (!acc[item.subCategory]) {
      acc[item.subCategory] = [];
    }
    acc[item.subCategory].push(item);
    return acc;
  }, {});
  var mcra = Object.entries(mcr);
  mcr &&
    sortedList.push({
      title: "Marketing Content",
      data: mcra,
      catID: "cat3",
      categoryImage:
        "https://api.pcloud.com/getpubthumb?code=XZzPIqXZy3hjNHsHCoflEtuqUHWPR5BMMHIV&linkpassword=undefined&size=1920x1080&crop=0&type=auto",
    }); //Marketing Content Array reduced to subcategories

  return { sortedList, projectList };
};
