import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

firebase.initializeApp({
  //config
  apiKey: "AIzaSyDGinou3luOvb9-C7U5YYGvneEQXj_hIgY",
  authDomain: "moss-pk.firebaseapp.com",
  projectId: "moss-pk",
  storageBucket: "moss-pk.appspot.com",
  messagingSenderId: "114358288457",
  appId: "1:114358288457:web:e4f92254492e464fc1ff98",
});

const auth = getAuth();
const storage = getStorage();
const storageRef = ref(storage);
const db = getFirestore();
// const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
  auth,
  storage,
  storageRef,
  db,
  // timestamp
};

////
// rules_version = '2';
// service cloud.firestore {
//   match /databases/{database}/documents {
//     match /{document=**} {
//       allow read, write: if false;
//     }
//   }
// }
