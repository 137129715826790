import React from "react";
import { ClientsSlider } from "../components";
import Whoweare from "../components/whoweare.component";
import ReactPlayer from "react-player";

export function Landing() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section ftco-no-pt ftco-no-pb ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row justify-content-end">
            <div className="col-md-10 mt-5">
              <h1 className="mb-5 hero-title">MOSS Architects</h1>
              <div className="row">
                <div className="col-md-8">
                  <p className="hero-text mb-4">
                    With an ambitious approach towards design, we take
                    creativity seriously and passionately to come up with
                    resolved & aesthetically beautiful design through
                  </p>
                </div>
              </div>
              <div className="row mb-lg-5">
                <div className="col-md-10 d-flex align-self-stretch ">
                  <div className="services d-block">
                    <div className="media-body">
                      <h1 className="heading mb-5">
                        Strategic Design, Effective Project Management and Smart
                        Execution
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-counter img" id="section-counter">
        <div className="ftco-section mb-5 container-fluid px-3 px-md-0">
          <div className="row d-md-flex align-items-center justify-content-end mb-5">
            <div className="col-md-10 ">
              <div className="row d-md-flex align-items-start">
                <div className="col-md d-flex justify-content-center counter-wrap ">
                  <div className="block-18">
                    <div className="text">
                      <strong className="number">11</strong>
                      <span>Years of Experience</span>
                    </div>
                  </div>
                </div>
                <div className="col-md d-flex justify-content-center counter-wrap ">
                  <div className="block-18">
                    <div className="text">
                      <strong className="number">77</strong>
                      <span>Happy Clients</span>
                    </div>
                  </div>
                </div>
                <div className="col-md d-flex justify-content-center counter-wrap ">
                  <div className="block-18">
                    <div className="text">
                      <strong className="number">101</strong>
                      <span>Finished Projects</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row justify-content-end">
          <div className="col-md-10 ">
            <div className="container-fluid px-5 px-md-0">
              <div className="row">
                <div className="col-md-11 project">
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url="https://vimeo.com/646611564"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ClientsSlider />

      <section className="ftco-section px-md-2 ">
        <div className="container-fluid px-md-0">
          <div className="row justify-content-end ">
            <div className="col-md-10">
              <div id="home" className="video-hero">
                <div className="container">
                  <div className="justify-content-center d-flex align-items-center">
                    <div className="text">
                      <h1 style={{ color: "grey" }}>
                        Who{" "}
                        <span>
                          <a href="/">We</a>
                        </span>{" "}
                        <br />
                        Are
                      </h1>
                    </div>
                    <p className="services-page-description mt-5 d-none d-md-block col-md-6 col-xl-8">
                      Far far away, behind the Margallah hills in a green
                      concrete jungle called Islamabad, is a group of architects
                      that always makes sure the development of your projects
                      fulfill all the requirements to provide you with a
                      productive and attractive space for a positive return and
                      investment.
                    </p>
                  </div>
                  <p className="services-page-description mt-5 mb-5 d-md-none d-sm-block text-justify">
                    Far far away, behind the Margallah hills in a green concrete
                    jungle called Islamabad, is a group of architects that
                    always makes sure the development of your projects fulfill
                    all the requirements to provide you with a productive and
                    attractive space for a positive return and investment.
                  </p>
                </div>

                <Whoweare />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
