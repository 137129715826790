import { AddProjectForm } from "./components";

export function AddProjectPage() {
  return (
    <div>
      <div id="colorlib-page">
        <div id="colorlib-main">
          <section className="ftco-section ftco-bread">
            <div className="container-fluid px-3 px-md-0">
              <div className="row no-gutters slider-text justify-content-end align-items-center">
                <div className="col-md-10">
                  <p className="breadcrumbs">
                    <span className="mr-2">
                      <a href="/">Home&nbsp;</a>
                    </span>
                    <span className="mr-2">
                      <a href="/admin">Admin&nbsp;</a>
                    </span>
                    <span>Add Project</span>
                  </p>
                  <h1 className="bread mt-lg-5 mt-md-4 mt-3">Add Project</h1>
                  <AddProjectForm />
                </div>
              </div>
            </div>
          </section>
          {/* END COLORLIB-MAIN */}
        </div>
        {/* END COLORLIB-PAGE */}
      </div>
    </div>
  );
}
