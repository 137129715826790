const Validator = require("validator");
const isEmpty = require("./is-empty");

export function validateProjectInput(data) {
  let errors = {};

  // data.vpath = !isEmpty(data.vpath) ? data.vpath : "";
  data.title = !isEmpty(data.title) ? data.title : "";
  data.category = !isEmpty(data.category) ? data.category : "";
  data.subCategory = !isEmpty(data.subCategory) ? data.subCategory : "";
  data.location = !isEmpty(data.location) ? data.location : "";
  data.clientName = !isEmpty(data.clientName) ? data.clientName : "";
  data.type = !isEmpty(data.type) ? data.type : "";
  data.builtArea = !isEmpty(data.builtArea) ? data.builtArea : "";
  data.description = !isEmpty(data.description) ? data.description : "";

  //title
  if (!Validator.isLength(data.title, { min: 7, max: 60 })) {
    errors.title = "title must be between 7 and 60 characters";
  }
  if (Validator.isEmpty(data.title)) {
    errors.title = "Title field is required";
  }
  //category
  if (Validator.isEmpty(data.category)) {
    errors.category = "Category field is required";
  }
  //subCategory
  if (Validator.isEmpty(data.subCategory)) {
    errors.subCategory = "Sub Category field is required";
  }
  //location
  if (!Validator.isLength(data.location, { min: 6, max: 80 })) {
    errors.location = "Location must be between 6 and 80 characters";
  }
  if (Validator.isEmpty(data.location)) {
    errors.location = "Location field is required";
  }
  //clientName
  if (!Validator.isLength(data.clientName, { min: 5, max: 60 })) {
    errors.clientName = "Client Name must be between 4 and 60 characters";
  }
  if (Validator.isEmpty(data.clientName)) {
    errors.clientName = "Client Name field is required";
  }
  //type
  if (!Validator.isLength(data.type, { min: 4, max: 60 })) {
    errors.type = "Type must be between 4 and 60 characters";
  }
  if (Validator.isEmpty(data.type)) {
    errors.type = "Type field is required";
  }
  //builtArea
  if (!Validator.isLength(data.builtArea, { min: 4, max: 60 })) {
    errors.builtArea = "Built Area must be between 4 and 60 characters";
  }
  if (Validator.isEmpty(data.builtArea)) {
    errors.builtArea = "Built Area field is required";
  }

  //description
  if (!Validator.isLength(data.description, { min: 10, max: 600 })) {
    errors.description = "Description must be between 10 and 600 characters";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description field is required";
  }
  // if (Validator.isEmpty(data.vpath)) {
  //   errors.vpath = "Please Select A Video";
  // }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
