import { Fragment } from "react";
import "./Project-Test-Two.scss";
import { ProjectCategoriesComponent } from "./project-categories.component";

export function ProjectCategories() {
  return (
    <Fragment>
      <div>
        <div id="colorlib-page">
          <div id="colorlib-main">
            <section className="ftco-section ftco-bread ftco-extend-mb">
              <div className="container-fluid px-3 px-md-0">
                <div className="row no-gutters slider-text justify-content-end align-items-center">
                  <div className="col-md-10">
                    <p className="breadcrumbs">
                      <span className="mr-2">
                        <a href="/">Home&nbsp;</a>
                      </span>
                      <span>Projects</span>
                    </p>
                    <h1 className="bread mt-lg-5 mt-md-4 mt-3">
                      Project Categories
                    </h1>

                    <ProjectCategoriesComponent />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
