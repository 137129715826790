import { useEffect, useState } from "react";

export function TestTask() {
  const [data, setData] = useState();
  const [headerValues, setHeaderValues] = useState([""]);

  useEffect(() => {
    fetch("https://hamzakhan2121.github.io/jsonapi/student.json")
      .then((response) => response.json())
      .then((data) => setData(data.Student))
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  }, []);
  data && console.log(data);

  return (
    <div>
      <div>
        <div id="colorlib-page">
          <div id="colorlib-main">
            <section className="ftco-section ftco-bread ftco-extend-mb">
              <div className="container-fluid px-3 px-md-0">
                <div className="row no-gutters slider-text justify-content-end align-items-center">
                  <div className="col-md-10">
                    <body>
                      <table style={{ width: "50em" }}>
                        <thead>
                          {data &&
                            Object.keys(data[0]).map((k) => <th>{k}</th>)}
                        </thead>
                        {data &&
                          data.map((obj) => (
                            <tr>
                              {Object.keys(obj).map((dat) => (
                                <td>{dat === "address" ? "" : obj[dat]}</td>
                              ))}
                              {/* <td>{obj.ID}</td>
                              <td>{obj.FirstName}</td>
                              <td>{obj.LastName}</td>
                              <td>{obj.Gender}</td>
                              <td>{obj.StudentStatus}</td> */}
                            </tr>
                          ))}
                      </table>
                    </body>

                    {/* 
                    {data &&
                        data.map((obj) => (
                          <>
                            {" "}
                            <div>First Name: {obj.FirstName}</div>
                            <div>Last Name: {obj.LastName}</div>
                            <div>Gender: {obj.Gender}</div>
                            <div>ID: {obj.ID}</div>
                            <div>Student Status: {obj.StudentStatus}</div>
                          </>
                        ))} 
                      */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
