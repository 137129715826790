import { Button } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";

export function Sidebar() {
  // var active = sessionStorage.getItem("active");
  const [active, setActive] = useState("landing");
  const [user] = useAuthState(auth);
  function signOutUser() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }
  return (
    <Fragment>
      <div id="colorlib-aside">
        <nav id="colorlib-main-menu" role="navigation">
          <ul>
            {/* colorlib-active classname assigned dynamically on each page. */}
            <li
              className={active === "landing" || "" ? "colorlib-active" : null}
              onClick={() => setActive("landing")}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={active === "projects" ? "colorlib-active" : null}
              onClick={() => setActive("projects")}
            >
              <Link to="/projects">Projects</Link>
            </li>

            <li
              className={active === "services" ? "colorlib-active" : null}
              onClick={() => setActive("services")}
            >
              <Link to="/services">Services</Link>
            </li>

            <li
              className={active === "contact" ? "colorlib-active" : null}
              onClick={() => setActive("contact")}
            >
              <Link to="/contact">Contact</Link>
            </li>
            {user && (
              <li
                className={active === "admin" ? "colorlib-active" : null}
                onClick={() => setActive("admin")}
              >
                <Link to="/admin">Admin</Link>
              </li>
            )}
          </ul>
          <p className="social ">
            <span>
              <a
                href="https://www.facebook.com/Moss-Architects-103796378696464"
                target="_blank"
                rel="noopener noreferrer"
              >
                facebook
              </a>
            </span>

            <span>
              <a
                href="https://www.instagram.com/moss.architects/"
                target="_blank"
                rel="noopener noreferrer"
              >
                instagram
              </a>
            </span>
          </p>
        </nav>
        <div className="colorlib-footer">
          {user && (
            <Button
              variant="outlined"
              className="mb-4"
              sx={{
                borderColor: "#ccb78f",
                color: "#ccb78f",
                fontWeight: "bold",
              }}
              onClick={() => signOutUser()}
            >
              Sign Out
            </Button>
          )}

          <h1 id="colorlib-logo">
            <a href="/">MOSS</a>
          </h1>
          <p id="madaviary-footer">
            © 2021 MOSS All rights reserved. <br /> Built By&nbsp;
            <a
              href="https://madaviary.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ letterSpacing: "1px" }}
            >
              Mad Aviary
            </a>
          </p>
        </div>
      </div>
    </Fragment>
  );
}
