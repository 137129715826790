import React, { Fragment } from "react";
import { ServicesComponent } from "../components/";

export function Services() {
  return (
    <Fragment>
      <div id="colorlib-page">
        <div id="colorlib-main">
          <section className="ftco-section ftco-bread mb-3">
            <div className="container-fluid px-3 px-md-0">
              <div className="row no-gutters slider-text justify-content-end align-items-center">
                <div className="col-md-10 ">
                  <p className="breadcrumbs">
                    <span className="mr-2">
                      <a href="/">Home</a>
                    </span>{" "}
                    <span>Services</span>
                  </p>
                  <h1 className="bread mt-lg-5 mt-md-4 mt-3">Services</h1>
                </div>
              </div>
            </div>
          </section>
          <ServicesComponent />
        </div>
      </div>
    </Fragment>
  );
}
