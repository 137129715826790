import React from "react";
import Slider from "react-slick";

const clients = [
  "Jade Group",
  "Pasha Group",
  "Bank Of Khyber",
  "Islamic Bank Of Khyber",
  "APS & Colleges",
  "Pakistan Navy",
  "Pakistan Army",
  "Government Of Pakistan",
  "Hardees",
  "Coffee Planet",
  "ChayeHub",
  "B&Z Couture",
  "Mouthful Restaurant",
  "Mr COD - Bahria town",
  "Mr. COD - Kabul",
  "J-7 Group",
];
export function ClientsSlider() {
  let settings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesPerRow: 4,
    rows: 2,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesPerRow: 1,
          slidesToScroll: 1,
          rows: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: { slidesPerRow: 2, rows: 2, infinite: true },
      },
      {
        breakpoint: 1024,
        settings: { slidesPerRow: 3, rows: 2, infinite: true },
      },
    ],
  };
  return (
    <section className="ftco-section px-5">
      <div className="container-fluid">
        <div className="row d-flex justify-content-end">
          <div className="col-md-10">
            <div className="col-md-11 text text-center client-and-partners ">
              <h1>
                Our <a href="/">Clients</a> and <a href="/">Partners</a>
              </h1>
            </div>
            <Slider
              {...settings}
              className="col-md-11 text-center landing-slider"
            >
              {clients.map((client, index) => (
                <div key={index}>
                  <h3>{client}</h3>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
