import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export function Admin() {
  return (
    <div>
      <div id="colorlib-page">
        <div id="colorlib-main">
          <section className="ftco-section ftco-bread">
            <div className="container-fluid px-3 px-md-0">
              <div className="row no-gutters slider-text justify-content-end align-items-center">
                <div className="col-md-10">
                  <p className="breadcrumbs">
                    <span className="mr-2">
                      <a href="/">Home&nbsp;</a>
                    </span>

                    <span>Admin</span>
                  </p>
                  <h1 className="bread mt-lg-5 mt-md-4 mt-3">Admin</h1>
                </div>
              </div>
            </div>
          </section>
          <div className="row no-gutters slider-text justify-content-center align-items-center">
            <div className="col-md-8 d-flex flex-column">
              <Link to="/add-project">
                <Button
                  variant="contained"
                  sx={{ m: 2, width: "200px" }}
                  color="success"
                >
                  Add Project
                </Button>{" "}
              </Link>
              <Link to="/edit-project">
                <Button variant="contained" sx={{ m: 2, width: "200px" }}>
                  Edit Project
                </Button>
              </Link>
              <Link to="/delete-project">
                <Button variant="contained" sx={{ m: 2, width: "200px" }}>
                  Delete Project
                </Button>
              </Link>
            </div>
          </div>
          {/* END COLORLIB-MAIN */}
        </div>
        {/* <NewProjectCategories/> */}
        {/* END COLORLIB-PAGE */}
      </div>
    </div>
  );
}
