import "./test.scss";
import { useProjectsContext } from "../../hooks";
import { Link } from "react-router-dom";

export function ProjectSubCategories() {
  const { state } = useProjectsContext();

  const { selectedCategory } = state;
  return (
    <span className="d-flex flex-column text-center">
      {selectedCategory.data.map((subCategory, index) => (
        <div key={index} className="mx-3">
          <span className="category-title text-wrap">
            {subCategory[0].toUpperCase()}
          </span>
          <div className="card__project12-list">
            {subCategory[1].map((project, index) => (
              <Link to={`/projects/${project.projectID}`} key={index}>
                {/* <a href={`/projects/${project.projectID}`} key={index}> */}
                <div className="card__project12 1">
                  <div className="card__project12_image text-center">
                    {project.title}
                    <img src={project.coverURL.url} alt="Project" />{" "}
                  </div>
                </div>
                {/* </a> */}
              </Link>
            ))}
          </div>
        </div>
      ))}
    </span>
  );
}
